import React from "react";
import LazyImage from "../hooks/LazyImage";
import clsx from "clsx";
const Card = ({ order, img, content, head }) => {
  const imageOrder =
    order === 1 ? "sm:order-2 md:order-1" : "sm:order-2 md:order-2";
  const textOrder =
    order === 1 ? "sm:order-1 md:order-2" : "sm:order-1 md:order-1";
  return (
    <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
      <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
        <div
          className={clsx(
            "col-span-2 row-span-2 h-full mb-4 text-justify flex flex-col justify-center",
            textOrder
          )}
        >
          <div className="w-full">
            <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase text-indigo-600 font-bold">
              {head}
            </p>

            <div className="text-base/7 text-gray-700 text-start ">
              <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                {content.map((cont, index) => (
                  <li key={index}>
                    <span className="font-bold">{cont.subhead}</span>
                    {cont.content}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={clsx("row-span-2", imageOrder)}>
          <LazyImage
            className="max-w-[275px] mx-auto"
            as="div"
            lowSrc={img}
            src={img}
            alt="railmadad"
            minHeight="200px"
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Card;
