import React from "react";
import LazyImage from "../hooks/LazyImage";
import getAllImages from "../static/imageUtils";
import ScreenMockup from "../Components/Mockup";
import AppCarouselSlider from "../Components/AppSlider";
import { WrmsFeatures, WrmsHeader } from "../static";
import Card from "../Components/wrms_component";
import WrmsHeaderSection from "../Components/wrms_heading";
export default function WrmsPage() {
  const { web_content, wrms } = getAllImages;
  // const appData = {
  //   Avatar: (
  //     <LazyImage
  //       as="span"
  //       src={web_content["railmadad(logo).webp"]}
  //       alt="WRMS"
  //       className="rounded-md border-1 flex-shrink-0"
  //     />
  //   ),
  //   AppInfo: {
  //     appname: "WRMS (Railops)",
  //     tagline: "Digital Monitoring tool of Station Cleanliness.",
  //     appurl:
  //       "https://play.google.com/store/apps/details?id=com.biputri.railmadad",
  //   },
  // };

  return (
    <>
      <WrmsHeaderSection />
      {WrmsFeatures.map((feature, index) => (
        <Card
          key={index}
          content={feature.content}
          head={feature.head}
          img={feature.img}
          order={feature.order}
        />
      ))}
    </>
  );
}
