import React, { Fragment } from "react";
import * as Icons from "lucide-react";
import { Link } from "react-router-dom";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

export default function CarrersPage() {
  const openings = [
    {
      position: "Android Developer (Fresher)",
      location: "Work from Home",
      salary: "As per industry standards",
      jobTypes: ["Full-time", "Part-Time"],
      aboutUs:
        "We extend all kinds of services. We're into web and app development primarily and we take all kinds of projects.",
      jobDescription:
        "As an Android Developer, you will be an integral part of our mobile app development team. This role is designed for talented individuals who are passionate about Android app development and eager to launch their career in the tech industry.",
      keyResponsibilities: [
        "Working on Android Development",
        "Write clean, efficient, and maintainable code.",
        "Debug and troubleshoot issues to ensure app performance.",
        "Stay updated with the latest Android development trends and technologies.",
        "Contribute to the entire app development lifecycle, from concept to delivery.",
      ],
      requirements: [
        "Basic understanding of Android app development concepts.",
        "Knowledge of programming languages such as Java or Kotlin.",
        "Strong problem-solving skills and attention to detail.",
        "Eagerness to learn and adapt to new technologies.",
        "Excellent communication and teamwork abilities.",
      ],
      benefits: [
        "Flexible work hours.",
        "Letter of recommendation.",
        "Career growth and advancement prospects.",
        "A collaborative and innovative work environment.",
      ],
      jobOffer: {
        openings: 10,
        whoCanApply: [
          "Are available for the work from home job/internship.",
          "Are available for a duration of 3 months.",
          "Have relevant skills and interests.",
          "Women wanting to start/restart their career can also apply.",
        ],
      },
      howToApply:
        "If you're enthusiastic about Android development and excited to embark on your career journey with us, please fill up the form on the careers page. We look forward to reviewing your application and having you join our team!",
    },
    {
      position: "Python Developer (Fresher)",
      location: "Work from Home",
      salary: "As per industry standards",
      jobTypes: ["Full-time", "Part-Time"],
      aboutUs:
        "We extend all kinds of services. We're into web and app development primarily and we take all kinds of projects.",
      jobDescription:
        "As a Python Developer, you will be an integral part of our team, focusing on Django or FastAPI development. This role is designed for talented individuals who are passionate about web development and eager to launch or advance their career in the tech industry.",
      keyResponsibilities: [
        "Working on Django or FastAPI development.",
        "Understanding and implementing REST APIs.",
        "Coordinating with the team to fulfill project requirements.",
      ],
      requirements: [
        "Strong knowledge of Python.",
        "Experience with Django or FastAPI.",
        "Strong problem-solving skills and attention to detail.",
        "Eagerness to learn and adapt to new technologies.",
        "Excellent communication and teamwork abilities.",
      ],
      benefits: [
        "Flexible work hours.",
        "Letter of recommendation.",
        "Career growth and advancement prospects.",
        "A collaborative and innovative work environment.",
      ],
      jobOffer: {
        openings: 10,
        whoCanApply: [
          "Are available for the work from home job/internship.",
          "Are available for a duration of 3 months.",
          "Have relevant skills and interests.",
          "Women wanting to start/restart their career can also apply.",
        ],
      },
      howToApply:
        "If you're enthusiastic about Python development and excited to embark on your career journey with us, please fill up the form on the careers page. We look forward to reviewing your application and having you join our team!",
    },
    {
      position: "Front End Developer (Fresher)",
      location: "Work from Home",
      salary: "As per industry standards",
      jobTypes: ["Full-time", "Part-Time"],
      aboutUs:
        "We extend all kinds of services. We're into web and app development primarily and we take all kinds of projects.",
      jobDescription:
        "As a Front End Developer, you will be an integral part of our mobile app development team. This role is designed for talented individuals who are passionate about Front End development and eager to launch their career in the tech industry.",
      keyResponsibilities: [
        "Working on Front End Development.",
        "Write clean, efficient, and maintainable code.",
        "Debug and troubleshoot issues to ensure app performance.",
        "Stay updated with the latest Front End development trends and technologies.",
        "Contribute to the entire app development lifecycle, from concept to delivery.",
      ],
      requirements: [
        "Basic understanding of Front End development concepts.",
        "Knowledge of programming languages such as JavaScript (React is preferable), HTML, CSS.",
        "Strong problem-solving skills and attention to detail.",
        "Eagerness to learn and adapt to new technologies.",
        "Excellent communication and teamwork abilities.",
      ],
      benefits: [
        "Flexible work hours.",
        "Letter of recommendation.",
        "Career growth and advancement prospects.",
        "A collaborative and innovative work environment.",
      ],
      jobOffer: {
        openings: 10,
        whoCanApply: [
          "Are available for the work from home job/internship.",
          "Are available for a duration of 3 months.",
          "Have relevant skills and interests.",
          "Women wanting to start/restart their career can also apply.",
        ],
      },
      howToApply:
        "If you're enthusiastic about Front End development and excited to embark on your career journey with us, please fill up the form on the careers page. We look forward to reviewing your application and having you join our team!",
    },
    {
      position: "IoT Hardware Developer (Fresher)",
      location: "Work from Home",
      salary: "As per industry standards",
      jobTypes: ["Full-time", "Part-Time"],
      aboutUs:
        "We extend all kinds of services. We're into web and app development primarily and we take all kinds of projects.",
      jobDescription:
        "As an IoT Hardware Developer, you will be an integral part of our development team. This role is designed for talented individuals who are passionate about IoT hardware development and eager to launch their career in the tech industry.",
      keyResponsibilities: [
        "Working on IoT hardware development.",
        "Write clean, efficient, and maintainable code.",
        "Debug and troubleshoot issues to ensure device performance.",
        "Stay updated with the latest IoT hardware development trends and technologies.",
        "Contribute to the entire product development lifecycle, from concept to deployment.",
      ],
      requirements: [
        "Basic understanding of IoT hardware development concepts.",
        "Knowledge of embedded systems, microcontrollers, and sensor integration.",
        "Experience with programming languages such as C, C++, or Python.",
        "Strong problem-solving skills and attention to detail.",
        "Eagerness to learn and adapt to new technologies.",
        "Excellent communication and teamwork abilities.",
      ],
      benefits: [
        "Flexible work hours.",
        "Letter of recommendation.",
        "Career growth and advancement prospects.",
        "A collaborative and innovative work environment.",
      ],
      jobOffer: {
        openings: 10,
        whoCanApply: [
          "Are available for the work from home job/internship.",
          "Are available for a duration of 3 months.",
          "Have relevant skills and interests.",
          "Women wanting to start/restart their career can also apply.",
        ],
      },
      howToApply:
        "If you're enthusiastic about IoT hardware development and excited to embark on your career journey with us, please fill up the form on the careers page. We look forward to reviewing your application and having you join our team!",
    },
  ];
  const [isOpenDialog, setIsOpenDialog] = React.useState({
    isOpen: false,
    dataIndex: null,
  });
  const job = openings[isOpenDialog.dataIndex];

  return (
    <>
      {!isOpenDialog.isOpen ? (
        <Fragment>
          <div className="p-5">
            <span className="w-fit mx-auto flex items-center justify-center bg-indigo-50 rounded-full text-indigo-600 text-center text-sm font-medium leading-5 px-3 py-1 mb-5">
              Careers at Sarva Suvidhaen
            </span>
            <h1 className="text-gray-900 text-center font-manrope lg:text-5xl text-4xl font-bold leading-tight ">
              Unlock new career <br /> opportunities at Sarva Suvidhaen{" "}
            </h1>
            <p className="text-gray-900 text-center text-lg font-normal leading-7">
              Join the Team Let's grow together!
            </p>
          </div>
          <div className="xl:max-w-6xl w-auto mx-auto xl:py-28 md:py-16 py-10 xl:px-0 px-10">
            <h3 className="text-gray-900 text-center lg:text-4xl text-3xl font-bold leading-10 mb-5">
              Who we're looking for
            </h3>
            <p className="text-gray-500 max-w-7xl text-center text-lg font-normal leading-7">
              At Sarva Suvidhaen , we believe in building a team of passionate
              individuals who are eager to make a meaningful impact. We're on
              the lookout for talented and dedicated professionals who share our
              commitment to excellence and innovation.
              <span className="bold">Qualities We Value:</span> We seek
              individuals who are driven by a passion for excellence in their
              work. Whether you're an experienced professional or a recent
              graduate, if you strive for perfection, you'll find a home here.
              Innovation is at the core of what we do. We welcome creative minds
              who can think outside the box and bring fresh perspectives to the
              table. Collaboration is key to our success. We value team players
              who can work seamlessly with colleagues, fostering a positive and
              collaborative work environment. In a dynamic and ever-changing
              industry, adaptability is crucial. We're looking for individuals
              who can thrive in a fast-paced environment and embrace challenges
              with a positive attitude.
            </p>
            <p className="text-gray-500 text-center text-lg font-normal leading-7 "></p>
          </div>
          <div className="lg:py-24 md:py-16 py-10 bg-slate-50 xl:px-0 px-10">
            <h2 className="text-gray-900 text-center font-manrope lg:text-4xl text-3xl font-bold leading-10 mb-14">
              Open positions
            </h2>
            <div className="lg:max-w-3xl md:max-w-xl sm:max-w-md max-w-sm mx-auto border border-slate-200 bg-white rounded-2xl p-12">
              <div className="flex justify-between gap-x-8 py-6 border-b border-gray-200 flex-col md:flex-row lg:flex-row gap-y-3">
                <h3 className="text-indigo-600 text-xl font-medium leading-8">
                  Python Developer
                </h3>
                <button
                  onClick={() =>
                    setIsOpenDialog({ isOpen: true, dataIndex: 1 })
                  }
                  className="w-30 h-10 md:w-20 md:h-9 rounded-full bg-indigo-50 hover:bg-indigo-100 transition-all duration-700 text-indigo-600 text-xs font-semibold leading-4"
                >
                  Apply
                </button>
              </div>

              <div className="flex justify-between gap-x-8 py-6 border-b border-gray-200 flex-col md:flex-row lg:flex-row gap-y-3">
                <h3 className="text-gray-900 text-xl font-medium leading-8">
                  Flutter Developer
                </h3>
                <button
                  onClick={() =>
                    setIsOpenDialog({ isOpen: true, dataIndex: 0 })
                  }
                  className="w-30 h-10 md:w-20 md:h-9 rounded-full bg-indigo-50 hover:bg-indigo-100 transition-all duration-700 text-indigo-600 text-xs font-semibold leading-4"
                >
                  Apply
                </button>
              </div>

              <div className="flex justify-between gap-x-8 py-6 border-b border-gray-200 flex-col md:flex-row lg:flex-row gap-y-3">
                <h3 className="text-gray-900 text-xl font-medium leading-8">
                  Designer
                </h3>
                <button
                  onClick={() =>
                    setIsOpenDialog({ isOpen: true, dataIndex: 2 })
                  }
                  className="w-30 h-10 md:w-20 md:h-9 rounded-full bg-indigo-50 hover:bg-indigo-100 transition-all duration-700 text-indigo-600 text-xs font-semibold leading-4"
                >
                  Apply
                </button>
              </div>
              <div className="flex justify-between gap-x-8 py-6 flex-col md:flex-row lg:flex-row gap-y-3">
                <h3 className="text-gray-900 text-xl font-medium leading-8">
                  IoT & hardware developer
                </h3>
                <button
                  onClick={() =>
                    setIsOpenDialog({ isOpen: true, dataIndex: 3 })
                  }
                  className="w-30 h-10 md:w-20 md:h-9 rounded-full bg-indigo-50 hover:bg-indigo-100 transition-all duration-700 text-indigo-600 text-xs font-semibold leading-4"
                >
                  Apply
                </button>
                
              </div>

              <div className="flex justify-between gap-x-8 pt-6 border-t border-gray-200 flex-col md:flex-row lg:flex-row gap-y-3">
                  <h3 className="text-gray-900 text-xl font-medium leading-8">
                    ⁠ML engineer
                  </h3>
                  <button
                    onClick={() =>
                      setIsOpenDialog({ isOpen: true, dataIndex: 0 })
                    }
                    className="w-30 h-10 md:w-20 md:h-9 rounded-full bg-indigo-50 hover:bg-indigo-100 transition-all duration-700 text-indigo-600 text-xs font-semibold leading-4"
                  >
                    Apply
                  </button>
                </div>
            </div>
          </div>

          <div className="xl:max-w-6xl w-auto mx-auto xl:py-28 md:py-16 py-10 xl:px-0 px-10">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-x-4 lg:gap-x-8 gap-y-4 lg:pb-24 pb-10 border-b border-gray-200 max-w-lg mx-auto md:max-w-3xl lg:max-w-full">
              <div className="flex flex-col items-center p-8 group  hover:rounded-2xl transition-all duration-500 hover:text-indigo-600 cursor-pointer">
                <div className="w-20 h-20 bg-indigo-50 rounded-xl flex items-center justify-center mb-5 max-auto transition-all duration-500 group-hover:bg-indigo-600">
                  <Icons.Timer className="text-indigo-600 transition-all duration-500 group-hover:text-white" />
                </div>
                <h4 className="text-gray-900 text-lg font-semibold leading-7 mb-3 max-lg:text-center">
                  Flexible hours
                </h4>
              </div>
              <div className="flex flex-col items-center p-8 group hover:rounded-2xl transition-all duration-500 hover:text-indigo-600 cursor-pointer">
                <div className="w-20 h-20 bg-indigo-50 rounded-xl flex items-center justify-center mb-5 max-lg:mx-auto transition-all duration-500 group-hover:bg-indigo-600">
                  <Icons.Utensils className="text-indigo-600 transition-all duration-500 group-hover:text-white" />
                </div>
                <h4 className="text-gray-900 text-lg font-semibold leading-7 mb-3 max-lg:text-center">
                  Free Snacks & Drinks
                </h4>
              </div>
              <div className="flex flex-col items-center px-8 group pt-8 lg:pb-8 pb-4 transition-all duration-500 hover:rounded-2xl hover:text-indigo-600 cursor-pointer md:col-span-2 lg:col-span-1 md:w-1/2 lg:w-full md:mx-auto">
                <div className="w-20 h-20 bg-indigo-50 rounded-xl flex items-center justify-center mb-5 max-lg:mx-auto transition-all duration-500 group-hover:bg-indigo-600">
                  <Icons.Gamepad2 className="text-indigo-600 transition-all duration-500 group-hover:text-white" />
                </div>
                <h4 className="text-gray-900 text-lg font-semibold leading-7 mb-3 max-lg:text-center">
                  Gaming
                </h4>
              </div>
              <div className="flex flex-col items-center px-8 group pt-8 lg:pb-8 pb-4 transition-all duration-500 hover:rounded-2xl hover:text-indigo-600 cursor-pointer md:col-span-2 lg:col-span-1 md:w-1/2 lg:w-full md:mx-auto">
                <div className="w-20 h-20 bg-indigo-50 rounded-xl flex items-center justify-center mb-5 max-lg:mx-auto transition-all duration-500 group-hover:bg-indigo-600">
                  <Icons.Calendar className="text-indigo-600 transition-all duration-500 group-hover:text-white" />
                </div>
                <h4 className="text-gray-900 text-lg font-semibold leading-7 mb-3 max-lg:text-center">
                  6 Working Days
                </h4>
              </div>
            </div>
            <div className=" bg-gradient-to-l from-violet-600 to-indigo-600 p-12 rounded-2xl ">
              <div className="lg:flex items-center justify-between gap-6">
                <div className="lg:mb-0 mb-10">
                  <h3 className="text-white font-manrope lg:text-4xl text-3xl font-semibold leading-10 mb-5">
                    Don't see the role you're interested in?
                  </h3>
                  <p className="text-indigo-100 text-xl font-normal leading-8">
                    We’re always looking for talented people to join our team.
                    Send us your CV and we will contact you for any future
                    roles.
                  </p>
                </div>
                <button
                  className="px-4 h-14 flex items-center justify-center text-indigo-600 text-lg font-semibold leading-7 gap-2 rounded-full bg-white whitespace-nowrap"
                  onClick={() =>
                    (window.location.href = "mailto:contact@suvidhaen.com")
                  }
                >
                  {" "}
                  Send Your CV{" "}
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.75 11L17.4167 11M12.8333 16.5L17.6852 11.6482C17.9907 11.3426 18.1435 11.1898 18.1435 11C18.1435 10.8102 17.9907 10.6574 17.6852 10.3518L12.8333 5.5"
                      stroke="#4F46E5"
                      stroke-width="2.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="max-w-7xl mt-10 mx-auto py-6 px-6 bg-white rounded-xl  space-y-8">
          <Link
            to="/career-page"
            className="flex gap-2 text-indigo-600 items-center "
            onClick={() => window.location.reload()}
          >
            <Icons.ArrowLeft className="h-5" />
            All Jobs
          </Link>
          <h1 className="text-gray-900 lg:text-4xl text-3xl font-bold mt-3 mb-0">
            {job?.position}
          </h1>
          <p className="text-gray-600 my-4 text-lg flex gap-2 ">
            <strong>Location -</strong>
            <span>{job?.location}</span>
          </p>
          <p className="text-gray-600 mt-3 flex gap-2">
            <strong>Salary -</strong> {job?.salary}
          </p>
          <p className="text-gray-600  flex gap-2">
            <strong>Job Types - </strong> {job?.jobTypes.join(", ")}
          </p>
          <p className="text-gray-600 flex flex-col gap-3">
            <strong>About Us</strong>
            <span className="max-w-3xl">{job?.aboutUs}</span>
          </p>
          <p className="text-gray-600 flex flex-col gap-3">
            <strong>Job Description</strong>
            <span className="max-w-3xl">{job?.jobDescription}</span>
          </p>

          <div>
            <h2 className="text-xl font-semibold">Key Responsibilities</h2>
            <ul className="space-y-1 text-gray-700">
              {job?.keyResponsibilities.map((resp, index) => (
                <li key={index} className="ml-6 py-1 list-disc">
                  {resp}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold ">Requirements</h2>
            <ul className="space-y-2 text-gray-700">
              {job?.requirements.map((req, index) => (
                <li key={index} className="ml-6 py-1 list-disc">
                  {req}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold ">Benefits</h2>
            <ul className="space-y-2 text-gray-700">
              {job?.benefits.map((benefit, index) => (
                <li key={index} className="ml-6 py-1 list-disc">
                  {benefit}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold ">Who Can Apply?</h2>
            <ul className="space-y-2 text-gray-700 ">
              {job?.jobOffer.whoCanApply.map((who, index) => (
                <li key={index} className="ml-6 py-1 list-disc">
                  {who}
                </li>
              ))}
            </ul>
          </div>

          <p className="text-gray-700 font-semibold max-w-3xl">
            {job?.howToApply}
          </p>

          <div className="flex space-x-4">
            <button
              className="w-32 h-10 rounded-lg bg-indigo-500 text-white font-semibold hover:bg-indigo-600 transition"
              onClick={() =>
              (window.location.href =
                "https://docs.google.com/forms/d/1wYJenXrIO8Ia7WIz9ndpskvFmG5H1JJX7W7Aw1STlMM/viewform?edit_requested=true")
              }
            >
              Apply Now
            </button>
          </div>
        </div>
      )}
    </>
  );
}
