import React from "react";
import { WrmsHeader } from "../static/index";
import getAllImages from "../static/imageUtils";
import LazyImage from "../hooks/LazyImage";
export default function WrmsHeaderSection() {
  const { intro, objective, advantage } = WrmsHeader;
  const objectiveLists = objective.lists;
  const embedID = "RdbqMY_rA8k";
  const { wrms } = getAllImages;
  return (
    <>
      {/* <section className="">
        <h2 className="text-center md:text-3xl text-2xl">
          <span className="font-bold">WRMS</span> - Water Refill Management
          System
        </h2>
        <p className="lg: text-center m-auto text-lg py-2 w-[80%]">
          Empowering Railway Operations with Seamless Water Management
        </p>
      </section>
      <section className="flex justify-center">
        <VideoCard embedId={embedID} />
      </section>
      <section className="py-10">
        <div className="className=" py-2>
          <h3 className="pb-2">Introduction</h3>
          <p className="text-lg">
            {intro.content}
          </p>
        </div>
        <div className="py-2">
          <h3 className="pb-2">Objective</h3>
          <p className="text-lg">
           {objective.content}
          </p>
          <ul className="list-disc pl-8 text-lg pt-2">
            {objectiveLists.map((list, index)=> {
              return <li key={index}>
                <span className="font-bold">
                 {list.listHead}{" : "}
                </span>
                {list.listContent}
              </li>
            })}
          
          </ul>
        </div>
        <div className="pt-2">
          <h3 className="pb-2">Advantages</h3>
          <ul className="list-disc pl-8 text-lg">
          
          </ul>
        </div>
      </section> */}

      <section className="relative shadow-[inset_0_-20px_-0_-0px_rgba(0,0,0,0.4)_blur] border-b-2 border-gray-100 isolate flex justify-between mx-auto max-w-7xl h-svh px-4 sm:px-6 lg:px-8 overflow-hidden bg-gray-50">
        <div className="absolute left-0 top-0 bottom-0 -z-10 w-full">
          <div className="absolute inset-0 h-full w-full bg-hero-background bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></div>
        </div>
        <div className="z-99 mx-auto max-w-2xl py-5">
          <div className="text-center">
            <h1
              className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-900"
              aria-level="1"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              <span className="font-bold">WRMS</span> - Water Refill Management
              System
            </h1>

            <p
              className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl font-medium text-gray-500 font-bold  "
              role="article"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Empowering Railway Operations with Seamless Water Management
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 relative">
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:gap-x-6 absolute top-0">
                {/* <ScreenMockup> */}
                <LazyImage
                  as="div"
                  lowSrc={wrms["dashboard.png"]}
                  src={wrms["dashboard.png"]}
                  alt="railmadad"
                  minHeight="200px"
                  width="100%"
                  height="auto"
                  className="max-w-[275px] mx-auto"
                />
                {/* </ScreenMockup> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>

        <div className="mt-6 max-w-6xl mx-auto flex flex-col gap-[1rem] mt-10 mb-2 text-justify">
          <h1 className="mb-4 text-3xl leading-tight font-bold   ">
            Introduction
          </h1>
          <p className="text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base">
            {intro.content}
          </p>

          <h2 className="mt-4 mb-4 text-3xl leading-tight font-bold   ">
            Objective
          </h2>
          <p className="text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base">
            {objective.content}
          </p>
          <ul className="space-y-2 text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base text-start">
            {objectiveLists.map((list, index) => {
              return (
                <li key={index}>
                  <span className="font-bold">
                    {list.listHead}
                    {" : "}
                  </span>
                  {list.listContent}
                </li>
              );
            })}
          </ul>
          <h2 className="mt-4 mb-4 text-3xl leading-tight font-bold   ">
            Advantages
          </h2>
          <ul className="space-y-2 text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base text-start">
            {advantage.map((list, index) => {
              return (
                <li key={index}>
                  <span className="font-bold">
                    {list.head}
                    {" : "}
                  </span>
                  {list.headContent}
                </li>
              );
            })}
          </ul>

          <h2 className="mt-6 mb-6 text-start text-4xl leading-tight font-bold   ">
            App Interface and Features
          </h2>
        </div>
      </section>
    </>
  );
}
