import React, { lazy } from "react";
import { Fragment, useRef, useState, useEffect } from "react";
import logo from "../static/assets/web_content/logo.png";
import { Link, useLocation } from "react-router-dom";
import * as Icons from "lucide-react";
import clsx from "clsx";
import LazyImage from "../hooks/LazyImage";
import getAllImages from "../static/imageUtils";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";

const { web_content } = getAllImages;
const navItems = [
  { id: 0, label: "Home", url: "/" },
  { id: 1, label: "About Us", url: "/about-us" },
  {
    id: 2,
    label: "Services",
    url: "/services",
    subMenu: [
      {
        label: "RailMadad Analytics",
        url: "/railmadad-analytics",
        description: "Data-driven insights for better railway services.",
        logo: "railmadad(logo).webp",
      },
      {
        label: "Sanchalak (RailOps)",
        url: "/sanchalak",
        description:
          "Real-time train tracking, passenger info & seamless travel management.",
        logo: "railops(logo).webp",
      },
      {
        label: "Swachhta Management",
        url: "/sm",
        description:
          "Efficient tracking & assessment of employee performance records.",
        logo: "SM(LOGO).webp",
      },
      {
        label: "WRMS (Jal Sampan)",
        url: "/wrms-jal-sampan",
        description: "Streamlines the water refilling process for trains.",
        logo: "wrms(logo).webp",
      },
    ],
  },
  { id: 3, label: "Portfolio", url: "/porfolio" },
  { id: 4, label: "Career", url: "/career-page" },
  { id: 5, label: "Contact Us", url: "/contact-us" },
];
const NavigationBar = () => {
  const { pathname } = useLocation();
  const [activeRoute, setActiveRoute] = useState(pathname);
  const buttonRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const throttle = (fn, delay) => {
    let timeout = null;
    return (...args) => {
      if (timeout) return;
      timeout = setTimeout(() => {
        fn(...args);
        timeout = null;
      }, delay);
    };
  };

  const handleScroll = throttle(
    () => setScrollPosition(window.pageYOffset),
    100
  );

  useEffect(() => setActiveRoute(pathname), [pathname]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  let timeout;
  const timeoutDuration = 10;
  const [openState, setOpenState] = useState(false);

  const toggleMenu = (open) => {
    setOpenState((openState) => !openState);
    buttonRef?.current?.click();
  };

  const onHover = (open, action) => {
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      clearTimeout(timeout);
      timeout = setTimeout(() => toggleMenu(open), timeoutDuration);
    }
  };

  const handleClick = (open) => {
    setOpenState(!open);
    clearTimeout(timeout);
  };
  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <>
      <Disclosure as="nav" className="z-[999] fixed top-0 left-0 right-0">
        {({ open }) => (
          <>
            <div
              className={clsx(
                "w-full ",
                scrollPosition > 50 && "bg-white/80 backdrop-blur-md"
              )}
            >
              <div className="flex h-16 items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-center justify-center">
                    <div style={{ maxWidth: "40px" }}>
                      <img
                        src={logo}
                        alt="logo"
                        style={{ width: "100%" }}
                      ></img>
                    </div>
                    <p className="m-0 p-0 text-sm font-semibold tracking-tight text-gray-900 uppercase leading-none">
                      Sarva Suvidhaen
                    </p>
                  </div>
                </div>
                <div className="hidden min-[800px]:block">
                  <div className="flex items-center gap-2">
                    <ul className="flex items-center gap-2 relative">
                      {navItems.map((item, index) => (
                        <li
                          data-index={index + 1}
                          aria-label={item.label}
                          key={item.label}
                          className=""
                        >
                          {item.label === "Services" ? (
                            <div
                              onMouseEnter={() => onHover(open, "onMouseEnter")}
                              onMouseLeave={() => onHover(open, "onMouseLeave")}
                            >
                              <Popover>
                                {({ open }) => (
                                  <>
                                    <PopoverButton
                                      ref={buttonRef}
                                      className={clsx("focus:outline-none")}
                                      onMouseEnter={() =>
                                        onHover(open, "onMouseEnter")
                                      }
                                      onMouseLeave={(e) => {
                                        const rect =
                                          buttonRef.current.getBoundingClientRect();
                                        if (
                                          e.clientX <= rect.left ||
                                          e.clientX >= rect.right
                                        ) {
                                          onHover(open, "onMouseLeave");
                                        }
                                      }}
                                    >
                                      <div
                                        className={clsx(
                                          "hover:bg-muted text-sm inline-flex items-center justify-center whitespace-nowrap rounded-lg font-semibold transition-colors hover:text-blue-600 h-10 px-3 py-1"
                                        )}
                                        style={{
                                          fontFamily:
                                            "Josefin Sans, sans-serif",
                                          textTransform: "capitalize",
                                        }}
                                        onClick={() => {
                                          handleClick(open);
                                        }}
                                      >
                                        {item.label}
                                        <Icons.ChevronDown
                                          className="w-4 h-4 ml-[.5] border-1 border-transparent transition-transform duration-300"
                                          style={{
                                            transform: open
                                              ? "rotate(-180deg)"
                                              : "rotate(0deg)",
                                          }}
                                        />
                                      </div>
                                    </PopoverButton>

                                    <PopoverComponent
                                      anchor={{
                                        to: "bottom start",
                                        gap: "4px",
                                      }}
                                      subMenu={item.subMenu}
                                      MouseLeaveEvent={() =>
                                        onHover(open, "onMouseLeave")
                                      }
                                      MouseEnterEvent={() =>
                                        onHover(open, "onMouseEnter")
                                      }
                                    />
                                  </>
                                )}
                              </Popover>
                            </div>
                          ) : (
                            <Link
                              key={item.label}
                              to={item.url}
                              className={clsx(
                                activeRoute === item.url
                                  ? "font-bold text-indigo-600"
                                  : "text-gray-700",
                                item.label === "Contact Us"
                                  ? "bg-indigo-600 text-[18px] inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-[600] ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-zinc-50 h-10 px-3 py-1"
                                  : "text-sm font-semibold transition-colors hover:text-indigo-600 px-3 py-1"
                              )}
                              style={{
                                fontFamily: "Josefin Sans, sans-serif",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.label}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="-mr-2 flex min-[800px]:hidden">
                  <DisclosureButton className="rounded-lg bg-indigo-600 px-2.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <Icons.X className="w-6 h-6 text-white" />
                    ) : (
                      <Icons.Equal className="w-6 h-6 text-white" />
                    )}
                  </DisclosureButton>
                </div>
              </div>
            </div>
            {open && (
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <DisclosurePanel className="shadow-md space-y-1 px-4 sm:px-4 fixed top-[60px] left-0 right-0 w-full bg-white border-t-2 border-zinc-200 max-h-[90vh] overflow-y-auto">
                  {({ close }) => (
                    <ul className="overflow-y-auto">
                      {navItems.map((item) => (
                        <li
                          key={item.label}
                          data-index={item.id + 1}
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          {item.id === 2 ? (
                            <Menu as="div" key={item.id} className="">
                              {({ open }) => (
                                <>
                                  <MenuButton
                                    ref={buttonRef}
                                    className={clsx(
                                      activeRoute === item.url
                                        ? "text-indigo-600 "
                                        : "text-zinc-800",
                                      "flex flex-row w-full justify-between gap-2 m-0 leading-none px-3 py-3 bg-indigo-50 hover:text-indigo-600 border-b border-gray-150 hover:text-indigo-600"
                                    )}
                                    style={{
                                      fontFamily: "Josefin Sans, sans-serif",
                                    }}
                                    onClick={() => {
                                      handleClick(open);
                                    }}
                                  >
                                    {item.label}

                                    <Icons.ChevronDown
                                      className="w-4 h-4 ml-[.5] border-1 border-transparent transition-transform duration-300"
                                      style={{
                                        transform: open
                                          ? "rotate(-180deg)"
                                          : "rotate(0deg)",
                                      }}
                                    />
                                  </MenuButton>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                  >
                                    <MenuItems className={clsx("bg-white ")}>
                                      {item.subMenu && (
                                        <ul className="flex flex-col gap-2 overflow-y-auto max-h-60">
                                          {item.subMenu.map(
                                            (subItem, index) =>
                                              /*index !== 0 &&
                                              index !== 3 && */ (
                                                <MenuItem key={subItem.label}>
                                                  {({ isActive }) => (
                                                    <li
                                                      role="menuitem"
                                                      id={`${item.id}-${subItem.id}`}
                                                      aria-label={subItem.label}
                                                      data-label={subItem.label}
                                                      data-id={subItem.id}
                                                      data-parent-id={item.id}
                                                    >
                                                      <Link
                                                        to={subItem.url}
                                                        className={clsx(
                                                          activeRoute ===
                                                            subItem.url
                                                            ? "text-indigo-600 bg-blue-50"
                                                            : "text-zinc-800",
                                                          "flex flex-col rounded-md hover:bg-indigo-50 hover:text-indigo-600 leading-none p-3 rounded-md focus:outline-none"
                                                        )}
                                                        style={{
                                                          fontFamily:
                                                            "Josefin Sans, sans-serif",
                                                        }}
                                                        onClick={async () =>
                                                          await close()
                                                        }
                                                      >
                                                        {subItem.label}
                                                      </Link>
                                                    </li>
                                                  )}
                                                </MenuItem>
                                              )
                                          )}
                                        </ul>
                                      )}
                                    </MenuItems>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          ) : (
                            <Link
                              to={item.url}
                              className={clsx(
                                activeRoute === item.url
                                  ? "text-indigo-600 "
                                  : "text-zinc-800",
                                "flex flex-row leading-none px-3 py-3 border-b border-gray-150 hover:bg-indigo-50 hover:text-indigo-600"
                              )}
                              style={{ fontFamily: "Josefin Sans, sans-serif" }}
                              onClick={() => close()}
                            >
                              {item.label}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </DisclosurePanel>
              </Transition>
            )}
          </>
        )}
      </Disclosure>
    </>
  );
};

const PopoverComponent = ({
  open,
  subMenu,
  anchor,
  MouseLeaveEvent,
  MouseEnterEvent,
}) => {
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <PopoverPanel
        static
        className=" absolute max-w-5xl right-0 top-[60px] z-[99999]"
        // anchor={{ to: "bottom start", gap: "10px" }}
        onMouseEnter={MouseEnterEvent}
        onMouseLeave={MouseLeaveEvent}
      >
        <div className="grid  bg-gray-50 shadow-xl rounded-xl p-3 grid-cols-1 border sm:grid-cols-2 lg:grid-cols-2 gap-3 lg:p-6 w-full">
          {subMenu.map(
            ({ url, label, description, logo }, subIndex) => 
            //   subIndex !== 0 &&
            // subIndex !== 3 && 
              (
                <Fragment
                  key={`${subIndex}-${label
                    .replace(/\s/g, "")
                    .slice(0, 10)}-${url.slice(0, 10)}`}
                >
                  <Link
                    data-index={subIndex}
                    to={url}
                    className={clsx(
                      "block p-4 bg-gray-50 rounded-lg border-2 bg-white border-transparent cursor-pointer text-md sm:text-base md:text-md transition-colors duration-300",
                      "hover:border-indigo-600"
                    )}
                    onMouseEnter={(e) => {
                      e.currentTarget
                        .querySelector("span:first-child")
                        ?.classList.add("text-indigo-600");
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget
                        .querySelector("span:first-child")
                        ?.classList.remove("text-indigo-600");
                    }}
                    onClick={MouseLeaveEvent}
                  >
                    {web_content[logo] && (
                      <LazyImage
                        as="span"
                        src={web_content[logo]}
                        alt={label}
                        className="w-10 h-10 mr-4 rounded-md border-1 flex-shrink-0"
                      />
                    )}
                    <span
                      className="font-bold text-gray-900 flex justify-between"
                      style={{ fontFamily: "Josefin Sans, sans-serif" }}
                    >
                      {label}
                      <Icons.ArrowRight className="w-4 h-4 ml-1 transition" />
                    </span>
                    <span
                      className="mt-2 text-gray-600"
                      style={{ fontFamily: "Josefin Sans, sans-serif" }}
                    >
                      {description}
                    </span>
                  </Link>
                </Fragment>
              )
          )}
        </div>
      </PopoverPanel>
    </Transition>
  );
};

export default NavigationBar;
