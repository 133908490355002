import React from "react";
import LazyImage from "../hooks/LazyImage";
import getAllImages from "../static/imageUtils";
import ScreenMockup from "../Components/Mockup";
import AppCarouselSlider from "../Components/AppSlider";

export default function RailMadadPage() {
  const { web_content, railmadad } = getAllImages;
  // const appData = {
  //   Avatar: (
  //     <LazyImage
  //       as="span"
  //       src={web_content["railmadad(logo).webp"]}
  //       alt="RailMadad"
  //       className="rounded-md border-1 flex-shrink-0"
  //     />
  //   ),
  //   AppInfo: {
  //     appname: "RailMadad (Railops)",
  //     tagline: "Digital Monitoring tool of Station Cleanliness.",
  //     appurl:
  //       "https://play.google.com/store/apps/details?id=com.biputri.railmadad",
  //   },
  // };

  return (
    <>
      <section className="relative shadow-[inset_0_-20px_-0_-0px_rgba(0,0,0,0.4)_blur] border-b-2 border-gray-100 isolate flex justify-between mx-auto max-w-7xl h-svh px-4 sm:px-6 lg:px-8 overflow-hidden bg-gray-50">
        <div className="absolute left-0 top-0 bottom-0 -z-10 w-full">
          <div className="absolute inset-0 h-full w-full bg-hero-background bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></div>
        </div>
        <div className="z-99 mx-auto max-w-2xl py-5">
          <div className="text-center">
            <h1
              className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-900"
              aria-level="1"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              RailMadad Analytics
            </h1>

            <p
              className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl font-medium text-gray-500 font-bold  "
              role="article"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Enhancing Customer Experience through Data
            </p>
            <p
              className="mt-4 text-foreground max-w-lg mx-auto text-gray-500"
              role="article"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              RailMadad Analytics enhances Indian Railways with data-driven
              insights to boost passenger satisfaction, streamline operations,
              and improve service quality.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 relative">
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:gap-x-6 absolute top-0">
                {/* <ScreenMockup> */}
                <LazyImage
                  as="div"
                  lowSrc={railmadad["udc.png"]}
                  src={railmadad["udc.png"]}
                  alt="railmadad"
                  minHeight="200px"
                  width="100%"
                  height="auto"
                />
                {/* </ScreenMockup> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="relative isolate flex h-screen items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/XuI5QqGvgtU?si=0NKe-ZONmBZaFI08"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div> */}

      <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>

        <div className="mt-3 mb-3 max-w-5xl mx-auto flex flex-col gap-[1rem] mt-10 mb-2 text-justify">
          <h1 className="mt-4 text-start mb-4 text-3xl leading-tight font-bold ">
            Benefits of RailMadad
          </h1>
          <p
            className="text-foreground text-gray-500"
            role="article"
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
          >
            RailMadad Analytics is a digital platform transforming how Indian
            Railways gathers and utilizes passenger feedback. It integrates
            data from multiple sources to provide deep insights into service
            quality, enabling informed decision-making for enhancing passenger
            satisfaction.
          </p>
          <h2 className="mt-3 mb-3 text-start text-4xl leading-tight font-bold   ">
            Objective
          </h2>
          <p
            className="text-foreground text-gray-500"
            role="article"
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
          >
            RailMadad Analytics project is to leverage advanced data
            collection, analysis, and visualization tools to enhance service
            quality and customer satisfaction across the Indian Railways
            network through informed decision-making and continuous
            improvement initiatives.
          </p>
          <h2 className="mt-3 mb-3 text-start text-4xl leading-tight font-bold   ">
            Advantages
          </h2>
          <ul className="space-y-2 flex flex-col gap-3 text-base leading-relaxed font-light md:text-sm lg:text-base text-start">
            <li className="text-foreground text-gray-700">
              <span className="font-bold text-zinc-950">
                Comprehensive Feedback Integration:
              </span>{" "}
              Centralizes feedback from multiple sources (online forms, mobile
              apps, paper surveys) into a single platform, ensuring all data
              is easily accessible and manageable.
            </li>
            <li className="text-foreground text-gray-700">
              <span className="font-bold text-zinc-950">Advanced Data Analysis:</span>{" "}
              Utilizes smart analytics to convert complex feedback data into
              clear insights, enabling railway authorities to identify trends,
              patterns, and areas needing improvement efficiently.
            </li>
            <li className="text-foreground text-gray-700">
              <span className="font-bold text-zinc-950">Enhanced Decision-Making:</span>{" "}
              Provides actionable information and performance metrics that
              empower railway managers to make informed decisions on resource
              allocation and service improvements..
            </li>
            <li className="text-foreground text-gray-700">
              <span className="font-bold text-zinc-950">User-Friendly Interface:</span>{" "}
              Offers intuitive data visualization tools such as charts and
              graphs, making it accessible for non-technical users to
              interpret and act upon feedback data effectively.
            </li>
            <li className="text-foreground text-gray-700">
              <span className="font-bold text-zinc-950">
                Scalability and Continual Improvement:
              </span>{" "}
              Designed to scale across the entire Indian Railways network,
              with provisions for regular updates and enhancements based on
              ongoing feedback and evolving requirements.
            </li>
          </ul>
          <h2 className="mt-6 mb-6 text-start text-4xl leading-tight font-bold   ">
            App Interface and Features
          </h2>
        </div>
      </section>

      <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 row-span-2 h-full mb-4 text-justify flex flex-col justify-center">
            <div className="w-full">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase text-indigo-600 font-bold">
                Unified Data Collection
              </p>

              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Gathering Feedback:</span>
                    Collect feedback from passengers through various channels,
                    like online forms, mobile apps, and even paper surveys.
                  </li>
                  <li>
                    <span className="font-bold">One-Stop Data Hub:</span>{" "}
                    Store all this feedback in one central place, making it
                    easy for you to view and manage.
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div className="row-span-2">
            <LazyImage
              as="div"
              lowSrc={railmadad["udc.png"]}
              src={railmadad["udc.png"]}
              alt="railmadad"
              minHeight="200px"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[3fr_2fr_2fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="row-span-2 order-1 mt-5">
            <LazyImage
              as="div"
              lowSrc={railmadad["an.png"]}
              src={railmadad["an.png"]}
              alt="railmadad"
              minHeight="200px"
              width="100%"
              height="auto"
            />
          </div>
          <div className="lg:order-last col-span-2 row-span-2 text-justify flex flex-col justify-center lg:ml-10 h-full">
            <div className="">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase  font-bold">
                Analytical Engine
              </p>
              <div className="text-base/7 text-gray-700 text-start">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Smart Analysis:</span>{" "}
                    Use advanced technology to analyze the feedback data.
                  </li>
                  <li>
                    <span className="font-bold">Easy-to-Understand Reports:</span>
                    Convert complex data into simple charts and graphs that
                    highlight important trends and patterns.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 row-span-2 mb-4 text-justify flex flex-col justify-center">
            <div className="w-full">
              <p className="mt-3 text-xl/8 text-gray-700 uppercase  font-bold">
                Feedback Analysis
              </p>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Detailed Breakdown:</span>{" "}
                    Analyze the feedback to show patterns over time, like how
                    many complaints were received each month.
                  </li>
                  <li>
                    <span className="font-bold">Visual Representation:</span>{" "}
                    See feedback as easy-to-read charts, like pie charts and
                    bar graphs, showing different types of complaints and
                    their frequency.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row-span-2">
            <LazyImage
              as="div"
              lowSrc={railmadad["feedback.png"]}
              src={railmadad["feedback.png"]}
              alt="railmadad"
              minHeight="200px"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[3fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="row-span-2 order-1 mt-5">
            <LazyImage
              as="div"
              lowSrc={railmadad["sqt.png"]}
              src={railmadad["sqt.png"]}
              alt="railmadad"
              minHeight="200px"
              width="100%"
              height="auto"
            />
          </div>
          <div className="lg:order-last col-span-2 row-span-2 text-justify flex flex-col justify-center lg:ml-10 h-full">
            <div className="w-full">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase  font-bold">
                Service Quality Tracking
              </p>
              <div className="text-base/7 text-gray-700 text-start">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold ">Multiple Dimensions:</span>
                    Track various aspects of service, such as cleanliness,
                    punctuality, and staff behavior.
                  </li>
                  <li>
                    <span className="font-bold">Performance Overview:</span>{" "}
                    Get a clear picture of how well each service area is
                    performing.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 row-span-2 mb-4 text-justify flex flex-col justify-center">
            <div className="w-full">
              <p className="mt-3 text-xl/8 text-gray-700 uppercase  font-bold">
                User Management
              </p>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Add and Manage Users:</span>{" "}
                    Admins can add new users and control what they can see and
                    do.
                  </li>
                  <li>
                    <span className="font-bold">Secure Access:</span>{" "}
                    Ensure that only authorized personnel can access sensitive
                    information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row-span-2">
            <LazyImage
              as="div"
              lowSrc={railmadad["um.png"]}
              src={railmadad["um.png"]}
              alt="railmadad"
              minHeight="200px"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[3fr_2fr_2fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="row-span-2 order-1 mt-5">
            <LazyImage
              as="div"
              lowSrc={railmadad["feedback.png"]}
              src={railmadad["feedback.png"]}
              alt="railmadad"
              minHeight="200px"
              width="100%"
              height="auto"
            />
          </div>
          <div className="lg:order-last col-span-2 row-span-2 text-justify flex flex-col justify-center lg:ml-10 h-full">
            <div className="w-full">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase  font-bold">
                Data Visualization
              </p>
              <div className="text-base/7 text-gray-700 text-start">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Visual Tools:</span>
                    Turn data into visual formats like graphs and charts.
                  </li>
                  <li>
                    <span className="font-bold">Downloadable Content:</span>{" "}
                    Easily download visuals for presentations or reports.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative mt-4 isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>

        <div className="mt-3 mb-3 max-w-5xl mx-auto flex flex-col gap-[1rem] mt-10 mb-2 text-justify">
          <h1 className="mt-4 text-start mb-4 text-3xl leading-tight font-bold ">
            How to Use
          </h1>
          <h3 className=" text-start text-4xl leading-tight font-bold   ">
            Data Collection and Upload
          </h3>
          <ul className="space-y-2 flex flex-col gap-3 text-base leading-relaxed font-light md:text-sm lg:text-base text-start">
            <li className="text-foreground text-gray-700">
              <span className="font-bold">Download Data:</span> Regularly
              download passenger feedback data from the ad system. Ensures
              the system always has the latest information for analysis.
            </li>
            <li className="text-foreground text-gray-700">
              <span className="font-bold">Upload Data:</span> Upload this
              data to the RailMadad Analytics system to kep-to-date. Ensures
              the system always has the latest information for analysis.
            </li>
          </ul>
          <h3 className=" text-start text-4xl leading-tight font-bold   ">
            Data Analysis and Visualization.
          </h3>
          <ul className="space-y-2 flex flex-col gap-3 text-base leading-relaxed font-light md:text-sm lg:text-base text-start">
            <li className="text-foreground text-gray-700">
              <span className="font-bold">Analyze Data:</span> Use the
              analytical engine to process and analyze the uploaded d Helps
              quickly identify problem areas and understand overall service
              performance.
            </li>
            <li className="text-foreground text-gray-700">
              <span className="font-bold">View Visuals:</span> Check the
              charts and graphs to understand feedback tre Helps quickly
              identify problem areas and understand overall service
              performance.
            </li>
          </ul>
          <h3 className=" text-start text-4xl leading-tight font-bold">
            User Onboarding and Management
          </h3>
          <ul className="space-y-2 flex flex-col gap-3 text-base leading-relaxed font-light md:text-sm lg:text-base text-start">
            <li>
              <span className="font-bold">Add Users:</span> Admins can add
              new users who need access to tem. Ensures the right people
              have access and can use the system effectively.
            </li>
            <li className="mt-2">
              <span className="font-bold">Set Permissions:</span> Define
              what each user can see and do based on ole. Ensures the right
              people have access and can use the system effectively.
            </li>
          </ul>
          <h3 className=" text-start text-4xl leading-tight font-bold">
            Feedback Analysis
          </h3>

          <ul className="space-y-2 flex flex-col gap-3 text-base leading-relaxed font-light md:text-sm lg:text-base text-start">
            <li>
              <span className="font-bold">Select Filters:</span> Choose
              filters like train number, coach number, and date range to
              narrow down the feedback. akes it easy to focus on specific
              areas of interest and understand passenger feedback in detail.
            </li>
            <li className="mt-2">
              <span className="font-bold">View Analysis:</span> Look at the
              interactive charts and graphs that show feedback patterns.
              akes it easy to focus on specific areas of interest and
              understand passenger feedback in detail.
            </li>
          </ul>


          <h3 className="text-start text-4xl leading-tight font-bold">
            Performance Tracking
          </h3>
          <ul className="space-y-2 flex flex-col gap-3 text-base leading-relaxed font-light md:text-sm lg:text-base text-start">
            <li>
              <span className="font-bold">Monitor Complaints:</span> Track
              how quickly and effectively complaints are being resolved.
              Helps in improving the response to passenger complaints and
              enhancing overall service quality.
            </li>
            <li className="mt-2">
              <span className="font-bold">Identify Trends:</span> Look for
              patterns in the data to identify staff or services that are
              performing well or poorly. Helps in improving the response to
              passenger complaints and enhancing overall service quality.
            </li>
          </ul>

          <h3 className="text-start text-4xl leading-tight font-bold">
            Report Generation
          </h3>
          <ul className="space-y-2 flex flex-col gap-3 text-base leading-relaxed font-light md:text-sm lg:text-base text-start">
            <li>
              <span className="font-bold">Generate Reports:</span> Use the
              system to create detailed reports on various metri Provides
              comprehensive documentation of service performance and areas
              for improvement.
            </li>
            <li className="mt-2">
              <span className="font-bold">Download Reports:</span> Download
              these reports for offline analysis or sharing with othe
              Provides comprehensive documentation of service performance
              and areas for improvement.
            </li>
          </ul>
        </div>
      </section>
      
      <section className="my-2">
        <div className="mx-auto text-center">
          <div className="py-2 text-lg flex-wrap ">
            <p>
              <span className="font-bold">Website:</span>{" "}
              <a
                rel="stylesheet"
                href="https://s2analytica.suvidhaen.com/auth/selector/"
                className="text-blue-600 hover:underline"
                target="_blank"
              >
                Railmadad Analytica
              </a>
            </p>
          </div>
        </div>
      </section>
      <div className="pt-4"></div>
      {/* <AppCarouselSlider
        {...appData}
        category={railmadad}
        images={getAllImages}
      /> */}
    </>
  );
}
