import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Button } from "@headlessui/react";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="w-full border-t-2 mt-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-3 md:gap-8 py-10 max-sm:max-w-sm max-sm:mx-auto gap-y-8">
          <div className="col-span-full mb-10 lg:col-span-2 lg:mb-0">
            <Link to="/" className="flex text-start justify-start">
              <span
                className="font-semibold uppercase"
                style={{ fontFamily: "Roboto" }}
              >
                Sarva Suvidhaen
              </span>
            </Link>
            <p className="py-4 text-md text-gray-500 lg:max-w-xs text-start">
              Our integrated platform, comprising a website and mobile app,
              streamlines employee performance record management.
            </p>
            <Button aria-label="Book a Demo" title="Book a Demo" as={Fragment}>
              {({ hover, active }) => (
                <button
                  className={clsx(
                    "rounded-lg  px-4 py-2 capitalize font-semibold shadow-xs border-2 border-indigo-600 text-indigo-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                    hover ? "hover:bg-indigo-600 hover:text-white " : "",
                    active ? "active:bg-indigo-700" : ""
                  )}
                  onClick={() => navigate("/book-demo")}
                >
                  <span className="sr-only">demo</span>
                  Contact US
                </button>
              )}
            </Button>
          </div>
          <div className="lg:mx-auto text-left ">
            <h4 className="text-lg text-gray-900 font-bold mb-7">
              Quick Links
            </h4>
            <ul
              className="text-sm  transition-all duration-500"
              style={{ fontFamily: "Roboto" }}
            >
              <li className="mb-6">
                <Link
                  to="/"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  Home
                </Link>
              </li>
              <li className="mb-6">
                <Link
                  to="/about-us"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  About us
                </Link>
              </li>
              <li className="mb-6">
                <Link
                  to="/portfolio"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  to="/career"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  carrer
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:mx-auto text-left ">
            <h4 className="text-lg text-gray-900 font-medium mb-7">Services</h4>
            <ul className="text-sm  transition-all duration-500">
              <li className="mb-6">
                <Link
                  to="/services"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  RailMadad Analytics
                </Link>
              </li>
              <li className="mb-6">
                <Link
                  to="/sanchalak"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  Sanchalak (RailOps)
                </Link>
              </li>
              <li className="mb-6">
                <Link
                  to="/sm"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  Swaccchta Management
                </Link>
              </li>
              <li>
                <Link
                  to="/wrms-jal-sampan"
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  WRMS (Jal Sampan)
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:mx-auto text-left">
            <h4 className="text-lg text-gray-900 font-medium mb-7">
              Resources
            </h4>
            <ul className="text-sm  transition-all duration-500">
              <li className="mb-6">
                <a
                  href=" "
                  className="font-medium text-gray-600 hover:text-gray-900"
                >
                  FAQs
                </a>
              </li>
              <li className="mb-6">
                <a
                  href=" "
                  className="font-medium  text-gray-600 hover:text-gray-900"
                >
                  Quick Start
                </a>
              </li>
              <li className="mb-6">
                <a
                  href=" "
                  className="font-medium  text-gray-600 hover:text-gray-900"
                >
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href=" "
                  className="font-medium  text-gray-600 hover:text-gray-900"
                >
                  User Guide
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="py-7 border-t border-gray-200">
          <div className="flex items-center justify-center flex-col lg:justify-between lg:flex-row my-2">
            <Link to="/" className="text-sm text-gray-500">
              Copyright &copy;<span> Sarva Suvidhaen.</span>{" "}
              {new Date().getFullYear()}, All rights reserved.
            </Link>
            <div className="flex space-x-4  lg:mt-0 ">
              <Link to="/" className="text-sm text-blue-500 capitalize">
                Privacy policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
