import React from "react";
import { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import NavigationBar from "./Components/Navbar";
import Homepage from "./pages/HomePage";
import SignupPage from "./pages/SignupPage";
import PageNotFound from "./pages/404";
import Footer from "./Components/Footer";
import SwachhtaPage from "./pages/SwachhtaPage";
import SanchalakPage from "./pages/SanchalakPage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage";
import CarrersPage from "./pages/CarrersPage";
import PortfolioPage from "./pages/PortfolioPage";
import RailMadadPage from "./pages/RailMadadPage";
import WrmsPage from "./pages/WrmsPage";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return null;
};
const PageRoutes = () => {
  const location = useLocation();
  const hideFooter = location.pathname === "/404" || location.pathname === '/signup';
  return (
    <>
      <ScrollToTop />
      <NavigationBar />
      <div style={{ position: "relative", top: "64px" }}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/sanchalak" element={<SanchalakPage />} />
          <Route path="/sm" element={<SwachhtaPage />} />
          <Route path="/railmadad-analytics" element={<RailMadadPage />} />
          <Route path="/wrms-jal-sampan" element={<WrmsPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/career-page" element={<CarrersPage />} />
          <Route path="/porfolio" element={<PortfolioPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          {/* <Route path="/404" element={<PageNotFound />} /> */}
        </Routes>
      </div>
      {!hideFooter &&  <Footer />}
    </>
  );
};

export default PageRoutes;
